<template>
<nav>
    <router-link to="/">Home</router-link>
</nav>    
</template>
<script>
export default{
    name: 'Home',
}
</script>
<style scoped>

</style>